/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type MessageGroup = {[x: string]: EchangeInterne[]}

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TabsContainer = styled(Tabs)`
  span {
    left: 0px;
    width: 5px;
  }
  button {
    align-items: start;
    padding-left: 20px;
  }
`

const TabLabel = styled.div`
  text-transform: uppercase;
  text-align: start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Circle = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.colors.main};
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface MessagesContainerCardProps {
  messages: EchangeInterne[];
  setSelectedCaseId: (value: string) => void;
}

const MessagesContainerCard: React.FC<MessagesContainerCardProps> = ({
  messages,
  setSelectedCaseId,
}) => {
  const auth = useAuthInfo()
  const [ tabValue, setTabValue ] = useState<number | boolean>(false)

  const sortMessage = () => {
    const sortedMessage: EchangeInterne[] = structuredClone(messages)
    const groups: MessageGroup = {}

    const sortMessageToGroup = (caseId: string): void => {
      if (!sortedMessage.length) return
      if (!groups[caseId]?.length) groups[caseId] = []
      if (caseId === sortedMessage[0].idDossier) {
        const toAdd = sortedMessage.shift()
        toAdd && groups[caseId].push(toAdd)
        sortMessageToGroup(caseId)
      }
      else {
        sortMessageToGroup(sortedMessage[0].idDossier ?? '')
      }
    }

    sortMessageToGroup(sortedMessage[0]?.idDossier ?? '')
    return groups
  }

  const handleTabChange = (newValue: number, caseId: string): void => {
    setTabValue(newValue)
    setSelectedCaseId(caseId)
  }

  const sortedMessage = useMemo(() => sortMessage(), [ messages ])

  useEffect(() => {
    if (Object.entries(sortedMessage).length > 0) {
      if (tabValue === false) {
        setTabValue(0)
        setSelectedCaseId(messages[0].idDossier)
      }
    }

  }, [ sortedMessage ])

  const isMe = (msg: EchangeInterne) => msg.destinataire.id === auth?.idAnnuaire

  return (
    <SummaryContainer>
      <TabsContainer
        value={tabValue === -1 ? false : tabValue}
        orientation="vertical"
      >
        {
          Object.entries(sortedMessage).map(([ caseId, messages ], index) => (
            <Tab
              key={`${caseId}-${index}`}
              onClick={() => handleTabChange(index, caseId)}
              label={
                <TabLabel>
                  <div>
                    Dossier
                    <br />
                    {caseId}
                  </div>
                  {
                    messages.filter((msg) => isMe(msg) && !msg.lu).length > 0 &&
                      <Circle>
                        {messages.filter((msg) => isMe(msg) && !msg.lu).length}
                      </Circle>
                  }
                </TabLabel>
              }
            />
          ))
        }
      </TabsContainer>
    </SummaryContainer>
  )
}

export default MessagesContainerCard
