/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { DatePicker } from 'formik-mui-x-date-pickers'

/* Type imports ------------------------------------------------------------- */
import type { FieldAttributes } from 'formik'

/* Component declaration ---------------------------------------------------- */
interface FormikDatePickerProps {
  name: string;
  disabled?: boolean;
}

const FormikDatePicker: React.FC<FieldAttributes<FormikDatePickerProps>> = ({
  name,
  ...props
}) => {
  return (
    <Field
      toolbarTitle="Choisir la date"
      component={DatePicker}
      name={name}
      inputFormat="dd/MM/yyyy"
      inputProps={{ placeholder: 'jj/mm/aaaa' }}
      {...props}
    />
  )
}

export default FormikDatePicker
