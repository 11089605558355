/* numberUtils  ------------------------------------------------------------- */
export const handleNumberVerification = (value: string | number, toFixed?: number): number => {
  const factor = Math.pow(10, toFixed ?? 0)

  const round = (number: number) => Math.floor(number * factor) / factor

  if (typeof value === 'number') {
    return toFixed !== undefined ? round(value) : value
  }
  const newValue = toFixed !== undefined ? round(parseFloat(value)) : parseFloat(value)
  return isNaN(newValue) ? 0 : newValue
}

export const handleNumberVerificationAllowEmpty = (value: string | number, toFixed?: number): number | '' => {
  const factor = Math.pow(10, toFixed ?? 0)

  const round = (number: number) => Math.floor(number * factor) / factor

  if (typeof value === 'number') {
    return toFixed !== undefined ? round(value) : value
  }
  const newValue = toFixed !== undefined ? round(parseFloat(value)) : parseFloat(value)
  return isNaN(newValue) ? '' : newValue
}
