/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
// import CaseListDocuments from './CaseListDocuments'

/* Type imports ------------------------------------------------------------- */
import type {
  DossierRecherche,
  // ResumeDocumentsDossier,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseListMobileCardProps {
  myCase: DossierRecherche;
  // documentsInfo?: ResumeDocumentsDossier;
  isFetchingDocumentsInfo: boolean;
  handleNavigate: () => void;
}

const CaseListMobileCard: React.FC<CaseListMobileCardProps> = ({
  myCase,
  // documentsInfo,
  isFetchingDocumentsInfo,
  handleNavigate,
}) => {

  // const getStyle = () => {
  //   if (!documentsInfo?.nombreMedia) {
  //     return ({ paddingLeft: 0, marginTop: 5 })
  //   }
  //   if (documentsInfo.nombreMedia >= 10) {
  //     return ({ paddingLeft: 10, marginTop: 10 })
  //   }
  //   return ({ paddingLeft: 10, marginTop: 10 })
  // }

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {myCase.id}
          </Link>
          <ColoredSquareChip
            color={getCaseStatusColor(myCase.statut.code)}
            smaller
          >
            {myCase.statut.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Assuré : ${myCase.assure}`}
        <br />
        {`Ref sinistre : ${myCase.referenceSinistre}`}
        <br />
        {`Type sinistre : ${myCase.natureSinistre.libelle}`}
        <br />
        {`Ville : ${myCase.ville}`}
        <br />
        {`Date : ${DateUtils.APIStrToLocalDateString(myCase.dateCreation)}`}
        <br />
        {`Date charte : ${DateUtils.APIStrToLocalDateString(myCase.dateCharte)}`}
        {/* <div style={getStyle()}>
          <CaseListDocuments
            documentsInfo={documentsInfo}
            caseId={myCase.id}
            isFetching={isFetchingDocumentsInfo}
          />
        </div> */}
      </CardContentContainer>
    </CardContainer>
  )
}

export default CaseListMobileCard
