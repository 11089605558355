/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonRendezVousTravaux } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvJalonCardProps {
  jalon: JalonRendezVousTravaux;
}

const CaseWorkflowWorksRdvJalonCard: React.FC<CaseWorkflowWorksRdvJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          RDV travaux
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.rendezVousTravaux[0]?.dateDebut ?? ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowWorksRdvJalonCard
