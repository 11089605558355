/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import {
  resetAuthInLocalStorage,
  setAuthInLocalStorage,
} from 'helpers/localStorageHelpers'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  LoginResponse,
  LoginResponseDossier,
} from 'API/__generated__/Api'
import type { RootState } from 'store/store'
import type { Nullable } from 'types/Nullable'

/* Type declarations -------------------------------------------------------- */
export type Login = LoginResponse & LoginResponseDossier

/* Redux slice -------------------------------------------------------------- */
const authSlice = createSlice(
  {
    name: 'auth',
    initialState: {
      dateValidite: null,
      token: null,
      nom: null,
      idAnnuaire: null,
      lectureSeule: false,
      referenceDossier: null,
    } as Nullable<Login>,
    reducers: {
      setAuthInfo: (state, { payload }: PayloadAction<Login>) => {
        setAuthInLocalStorage(payload)
        OpenAPI.setSecurityData(payload)
        return payload
      },
      resetAuthInfo: (state) => {
        resetAuthInLocalStorage()
        OpenAPI.setSecurityData(null)
        return { dateValidite: null, nom: null, token: null, idAnnuaire: null, lectureSeule: false, referenceDossier: null }
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setAuthInfo,
  resetAuthInfo,
} = authSlice.actions

export default authSlice.reducer

export const selectAuthInfo = (state: RootState): Nullable<Login> => {
  return state.auth
}
