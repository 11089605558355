/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  usePatchQuoteStateMutation,
  useGetCaseWorkflowQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import BackButton from 'components/BackButton/BackButton'

/* Type imports ------------------------------------------------------------- */
import { StatutDevis } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

/* Component declaration ---------------------------------------------------- */
interface QuotePageProps {}

const QuotePage: React.FC<QuotePageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)
  const [
    changeQuoteState,
    { isLoading: isSendingQuoteState },
  ] = usePatchQuoteStateMutation()

  const onClickChangeQuoteState = (accept: boolean) => {
    changeQuoteState({ dossier: caseId, numeroDevis: workflow?.jalonDevis.devis?.sequence ?? 0, statut: accept ? StatutDevis.Valide : StatutDevis.Annule })
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors ${accept ? 'de la validation' : 'du refus'} du devis.`)
        } else if (response) {
          toast.success(`Le devis à bien été ${accept ? 'validé' : 'refusé'}.`)
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
  }

  return (
    <div>
      <BackButton onClick={() => navigate(`/dossiers/${caseId}`)}>
        Retourner au suvi
      </BackButton>
      <LargeTitle>
        Devis
        <DuoButtonContainer>
          <LongButton
            variant="outlined"
            onClick={() => onClickChangeQuoteState(false)}
            disabled={isSendingQuoteState}
          >
            Refuser
          </LongButton>
          <LongButton
            variant="contained"
            onClick={() => onClickChangeQuoteState(true)}
            disabled={isSendingQuoteState}
          >
            Valider
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      {
        isFetchingWorkflow ?
          <CircularProgress /> :
          workflow?.jalonDevis.piecejointe &&
            <div>
              <iframe
                src={workflow.jalonDevis.piecejointe?.url ?? ''}
                width="100%"
                height="700px"
                title={workflow.jalonDevis.piecejointe?.libelle ?? 'Devis'}
              />
            </div>
      }
      <Footer />
    </div>
  )
}

export default QuotePage
