/* Framework imports -------------------------------------------------------- */
import React, {
  useState,
  useEffect,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useWindowSize } from 'helpers/hooks/useWindowSize'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOptionType } from 'types/SegmentedButton'

/* Type declarations -------------------------------------------------------- */
type Offset = {left: number; width: number}

/* Styled components -------------------------------------------------------- */
interface SegmentedButtonsContainerProps {
  offset: Offset;
}

const SegmentedButtonsContainer = styled.div<SegmentedButtonsContainerProps>`
  --highlight-width: ${(props) => props.offset.width}px;
  --highlight-x-pos: ${(props) => props.offset.left}px;

  display: flex;
  width: 100%;
`

interface ControllerProps {
  smaller?: boolean;
  disabled: boolean;
  offset: Offset;
}

const Controller = styled.div<ControllerProps>`
  border-style: solid;
  border-color: ${(props) => props.theme.colors.grey};
  border-width: 1px;
  border-radius: ${(props) => props.smaller ? '5px' : '10px'};

  background-color: ${(props) => props.theme.palette.background.paper};
  background: white;

  width: 100%;
  padding: ${(props) => props.smaller ? '3px' : '5px'};
  margin: auto;

  justify-content: space-between;
  overflow: hidden;
  position: relative;
  display: inline-flex;

  input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
    height: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  &:before {
    content: "";
    background: ${(props) => props.disabled ? props.theme.palette.info.dark : props.theme.palette.primary.main};
    border-radius: ${(props) => props.smaller ? '3px' : '5px'};
    position: absolute;
    top: ${(props) => props.smaller ? '3px' : '5px'};
    bottom: ${(props) => props.smaller ? '3px' : '5px'};
    z-index: 0;
    transition: left 0.3s ease, width 0.3s ease;
  }

  &.ready {
    &:before {
      left: var(--highlight-x-pos);
      width: var(--highlight-width);
    }
  }
`

const SegmentedButton = styled.div<ControllerProps>`
  width: 100%;
  position: relative;
  text-align: center;
  align-self: center;
  z-index: 1;

  label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    padding: ${(props) => props.smaller ? '5px' : '10px'};
    &.labelrdy {
      transition: color 0.3s ease;
    }
  }

  &.active {
    label {
      color: ${(props) => props.theme.colors.main};
    }
  }
`

/* Component declaration ---------------------------------------------------- */
interface SegmentedButtonsProps {
  options: SegmentedButtonOptionType[];
  setSelectedOption: (pOption: string) => void;
  selectedOption?: string;
  smaller?: boolean;
  disabled?: boolean;
}

const SegmentedButtons: React.FC<SegmentedButtonsProps> = ({
  options,
  setSelectedOption,
  selectedOption,
  smaller,
  disabled = false,
}) => {
  const [ activeIndex, setActiveIndex ] = useState<number>(-1)
  const [ offset, setOffset ] = useState<Offset>({ left: 0, width: 0 })
  const windowSize = useWindowSize()

  useEffect(() => {
    const seg = options.map((s) => s.value)

    if (selectedOption !== undefined && seg.indexOf(selectedOption) !== -1) {
      setActiveIndex(seg.indexOf(selectedOption))
    }
  }, [
    selectedOption,
    options,
  ])

  useEffect(() => {
    if (options.length < 1 || options[activeIndex] === undefined) {
      return
    }
    const activeSegmentRef = options[activeIndex].ref
    const { offsetLeft, offsetWidth } = activeSegmentRef.current as HTMLDivElement

    setOffset({ left: offsetLeft, width: offsetWidth })
  }, [
    activeIndex,
    setSelectedOption,
    options,
    windowSize,
  ])

  const onInputChange = (value: string, index: number): void => {
    setActiveIndex(index)
    setSelectedOption(value)
  }

  return (
    <SegmentedButtonsContainer offset={offset}>
      <Controller
        className={offset.left === 0 ? ' ' : ' ready'}
        smaller={smaller}
        disabled={disabled}
        offset={offset}
      >
        {
          options.map((item, i) => (
            <SegmentedButton
              key={`${item.value}-${i}`}
              className={` ${i === activeIndex ? 'active' : 'inactive'}`}
              ref={item.ref as React.MutableRefObject<HTMLDivElement>}
              smaller={smaller}
              disabled={disabled}
              offset={offset}
            >
              <label
                htmlFor={item.value}
                className={offset.left === 0 ? ' ' : ' labelrdy'}
              >
                {item.label ?? item.value}
              </label>
              <input
                type="radio"
                value={item.value}
                id={item.value}
                name="Segmented Calendar Buttons"
                onChange={() => onInputChange(item.value, i)}
                checked={i === activeIndex}
                disabled={disabled}
              />
            </SegmentedButton>
          ))
        }
      </Controller>
    </SegmentedButtonsContainer>
  )
}

export default SegmentedButtons
